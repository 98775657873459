const getTypeSettings = (id, isFunctional) => {
	let typeSettings;

	switch (id) {
		case 0:
			typeSettings = {
				name: {
					fi: "Roope-satama",
					sv: "Sälle-hamn",
					en: "Roope harbour"
				},
				color: "#0072bc",
				icon: "icon-satama.png"
			};
			break;
		case 1:
			typeSettings = {
				name: {
					fi: "Roope-palvelupiste",
					sv: "Sälle-servicestation",
					en: "Roope service station"
				},
				color: "#0072bc",
				icon: "icon-palvelupiste.png"
			};
			break;
		case 2:
			const icon = isFunctional
				? "icon-septi-green.png"
				: "icon-septi-red.png";
			const color = isFunctional ? "#01c283" : "#d24654";
			typeSettings = {
				name: {
					fi: "Septiasema",
					sv: "Septikanläggning",
					en: "Wastewater pump-out station"
				},
				color,
				icon
			};
			break;
		default:
			break;
	}
	return typeSettings;
};

export default getTypeSettings;
