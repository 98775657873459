const translations = {
	fi: {
		head: {
			title: "ROOPE-palvelukartta",
			description:
				"Roope-palvelut ovat vesilläliikkujille tarkoitettuja PSS ry:n tarjoamia ja ylläpitämiä palveluita merialueilla ja Järvi-Suomessa. Roope-palvelut edesauttavat vesiympäristömme puhtautta ja roskattomuutta sekä vähentävät rehevöitymistä."
		},
		header: {
			heading: "ROOPE",
			subheading: "palvelukartta"
		},
		info: {
			heading: "Info",
			legends: "Kartan selitteet",
			contacts: "Yhteystiedot"
		},
		settings: {
			heading: "Asetukset",
			filtering: "Suodatus",
			show: "Näytä",
			reset: "Poista valinnat",
			requiredServices: "Tarvittavat Roope-palvelut",
			requiredWasteServices: "Tarvittavat jätepalvelut",
			results: "Näytä %{count} kohdetta",
			results_1: "Näytä %{count} kohde",
			results_0: "Ei tuloksia!",
			languageSelection: "Kielivalinta"
		},
		viewBar: {
			view: "Näkymä",
			map: "Kartta",
			list: "Lista",
			showing: "Näytetään",
			results: "%{count} kohdetta",
			results_1: "%{count} kohde",
			results_0: "Ei tuloksia!"
		},
		locationDetails: {
			services: "Roope-palvelut",
			wasteServices: "Jätteiden vastaanotto",
			isFunctional: "Käytössä",
			isNotFunctional: "Ei käytössä",
			isServiced: "Huollossa",
			floatingSeptic: "Kelluva septiasema",
			septicId: "Septinumero",
			portId: "Satamanumero",
			seaMap: "Merikartta",
			coordinates: "Koordinaatit",
			website: "Verkkosivusto",
			reportIssue: "Ilmoita ongelmasta",
			issueDescription: "Vikakuvaus",
			issueType: "Valitse vian tyyppi",
			send: "Lähetä",
			cancel: "Peruuta",
			postSuccess: "Kiitos ilmoituksesta!",
			postError: "Virheraportin lähetys epäonnistui!"
		},
		mapView: {
			userPosition: "Oma sijainti"
		},
		listView: {
			name: "Nimi",
			number: "nro",
			seaMap: "Merikartta",
			selectArea: "Valitse alue",
			allAreas: "Kaikki"
		},
		areas: [
			"Saimaa",
			"Saaristomeri",
			"Pohjanlahti",
			"Päijänne",
			"Pirkanmaa",
			"Suomenlahti",
			"Ahvenanmaa"
		],
		issueTypes: ["Septi täynnä", "Pumppu rikki", "Muu vika, mikä?"],
		loading: "Ladataan",
		sending: "Lähetetään",
		close: "Sulje"
	},
	sv: {
		head: {
			title: "SÄLLE-servicekarta",
			description:
				"Sälle-tjänsterna är de tjänster som HSR rf erbjuder och upprätthåller för folk som rör sig till sjöss både på havsområdena och i Insjöfinland. Sälle-tjänsterna bidrar till att hålla vår vattenmiljö ren och skräpfri samt minskar övergödningen."
		},
		header: {
			heading: "SÄLLE",
			subheading: "servicekarta"
		},
		info: {
			heading: "Info",
			legends: "Kartans förklaringar",
			contacts: "Kontakta"
		},
		settings: {
			heading: "Inställningar",
			filtering: "Filtrera sökresultat",
			show: "Visa",
			reset: "Återställ filter",
			requiredServices: "Sälle-tjänster som söks",
			requiredWasteServices: "Nödvändiga avfallstjänster",
			results: "Visa %{count} objekt",
			results_1: "Visa %{count} objekt",
			results_0: "Inga resultat!",
			languageSelection: "Språk"
		},
		viewBar: {
			view: "Se",
			map: "Karta",
			list: "Lista",
			showing: "Visar",
			results: "%{count} objekt",
			results_1: "%{count} objekt",
			results_0: "Inga resultat!"
		},
		locationDetails: {
			services: "Sälle-tjänster",
			wasteServices: "Mottagning av avfall",
			isFunctional: "I bruk",
			isNotFunctional: "Ur bruk",
			isServiced: "I underhåll",
			floatingSeptic: "Flytande septikanläggning",
			septicId: "Septiknummer",
			portId: "Hamnnummer",
			seaMap: "Sjökort",
			coordinates: "Koordinater",
			website: "Webbplats",
			reportIssue: "Meddela om problem",
			issueDescription: "Problembeskrivning",
			issueType: "Välj typ av problem",
			send: "Skicka",
			cancel: "Tillbaka",
			postSuccess: "Tack för meddelandet!",
			postError: "Skickandet av felrapporten misslyckades!"
		},
		mapView: {
			userPosition: "Din plats"
		},
		listView: {
			name: "Namn",
			number: "Nummer",
			seaMap: "Sjökort",
			selectArea: "Välj område",
			allAreas: "Allt"
		},
		areas: [
			"Saimen",
			"Skärgårdshavet",
			"Bottniska viken",
			"Päijänne",
			"Birkaland",
			"Finska viken",
			"Åland"
		],
		issueTypes: [
			"Anläggningen är full",
			"Pumpen är sönder",
			"Annat fel, vilket?"
		],
		loading: "Laddar",
		sending: "Meddelandet skickas",
		close: "Stänga"
	},
	en: {
		head: {
			title: "ROOPE service map",
			description:
				"Keep the Archipelago Tidy Association maintains the Roope services intended primarily for those travelling in and around Finnish maritime and lake regions."
		},
		header: {
			heading: "ROOPE",
			subheading: "service map"
		},
		info: {
			heading: "Info",
			legends: "Map legends",
			contacts: "Contacts"
		},
		settings: {
			heading: "Settings",
			filtering: "Filter locations",
			show: "Show",
			reset: "Reset filters",
			requiredServices: "Required Roope services",
			requiredWasteServices: "Required waste services",
			results: "Show %{count} locations",
			results_1: "Show %{count} location",
			results_0: "No results!",
			languageSelection: "Language"
		},
		viewBar: {
			view: "View",
			map: "Map",
			list: "List",
			showing: "Showing",
			results: "%{count} locations",
			results_1: "%{count} location",
			results_0: "No results!"
		},
		locationDetails: {
			services: "Roope services",
			wasteServices: "Waste services",
			isFunctional: "Functional",
			isNotFunctional: "Not functional",
			isServiced: "In maintenance",
			floatingSeptic: "Floating wastewater pump-out station",
			septicId: "Septic waste pump-out station number",
			portId: "Harbour number",
			seaMap: "Nautical chart",
			coordinates: "Coordinates",
			website: "Website",
			reportIssue: "Report issue",
			issueDescription: "Issue description",
			issueType: "Select issue type",
			send: "Send",
			cancel: "Cancel",
			postSuccess: "Thank you for the message!",
			postError: "Sending the issue report failed!"
		},
		mapView: {
			userPosition: "Your location"
		},
		listView: {
			name: "Name",
			number: "Number",
			seaMap: "Nautical chart",
			selectArea: "Select area",
			allAreas: "All"
		},
		areas: [
			"Saimaa",
			"Archipelago Sea",
			"Gulf of Bothnia",
			"Lake Päijänne",
			"Pirkanmaa",
			"Gulf of Finland",
			"Åland"
		],
		issueTypes: [
			"Septic tank is full",
			"The pump is broken",
			"Other issue, what?"
		],
		loading: "Loading",
		sending: "Sending",
		close: "Close"
	}
};

export default translations;
