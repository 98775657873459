import axios from "axios";
import baseUrl from "../utils/config";

const getLocations = async () => {
	let response;
	if (!navigator.onLine) {
		response = JSON.parse(localStorage.getItem("locations"));
	} else {
		response = await axios.get(`${baseUrl}/locations`);
		localStorage.setItem("locations", JSON.stringify(response));
	}
	return response.data;
};

export default { getLocations };
