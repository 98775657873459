import axios from "axios";
import baseUrl from "../utils/config";

const postReport = async (location, issueType, otherIssue) => {
	const data = {
		form: {
			septic_name: location.name.fi,
			septic_id: location.id,
			septic_fault_id: issueType,
			septic_other_fault: otherIssue
		}
	};
	const response = await axios.post(`${baseUrl}/send-form`, data);
	return response.data;
};

export default { postReport };
