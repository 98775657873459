import reportService from "../services/report";

export const postReport = (location, issueType, otherIssue) => {
	return async dispatch => {
		dispatch({
			type: "POST_REPORT_REQUEST"
		});

		try {
			const report = await reportService.postReport(
				location,
				issueType,
				otherIssue
			);
			dispatch({
				type: "POST_REPORT_SUCCESS",
				data: report
			});
		} catch (error) {
			dispatch({
				type: "POST_REPORT_FAILURE",
				data: error
			});
		}
	};
};

export const clearReportStatus = () => {
	return {
		type: "CLEAR_REPORT_STATUS"
	};
};

const reportReducer = (state = [], action) => {
	switch (action.type) {
		case "POST_REPORT_REQUEST":
			return { ...state, isPosting: true };
		case "POST_REPORT_SUCCESS":
			return {
				...state,
				isPosting: false,
				isSuccess: true,
				result: action.data
			};
		case "POST_REPORT_FAILURE":
			return {
				...state,
				isPosting: false,
				hasPostError: true,
				errorMessage: action.data.message
			};
		case "CLEAR_REPORT_STATUS": {
			return [];
		}
		default:
			return state;
	}
};

export default reportReducer;
