import locationsService from "../services/locations";

export const fetchLocations = () => {
	return async dispatch => {
		dispatch({
			type: "FETCH_LOCATIONS_REQUEST"
		});

		try {
			const locations = await locationsService.getLocations();
			dispatch({
				type: "FETCH_LOCATIONS_SUCCESS",
				data: locations
			});
		} catch (error) {
			dispatch({
				type: "FETCH_LOCATIONS_FAILURE",
				data: error
			});
		}
	};
};

const locationsReducer = (state = [], action) => {
	switch (action.type) {
		case "FETCH_LOCATIONS_REQUEST":
			return { ...state, isFetching: true };
		case "FETCH_LOCATIONS_SUCCESS":
			return {
				...state,
				isFetching: false,
				data: action.data,
				hits: action.data.length
			};
		case "FETCH_LOCATIONS_FAILURE":
			return {
				...state,
				isFetching: false,
				hasFetchError: true,
				errorMessage: action.data.message
			};
		default:
			return state;
	}
};

export default locationsReducer;
