import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import styled, { css } from "styled-components";
import { ReactComponent as IconSearch } from "../images/icon-search.svg";

const ResultsButton = ({ history }) => {
	const { hits: allHits, isFetching } = useSelector(state => state.locations);
	const { hits: filteredHits } = useSelector(state => state.filters);
	const hits = filteredHits !== undefined ? filteredHits : allHits;

	return (
		<Button
			type="submit"
			onClick={() => history.goBack()}
			disabled={hits === 0 ? true : false}
		>
			<StyledIconSearch />

			{isFetching ? (
				<Translate value="loading" />
			) : (
				<Translate value="settings.results" count={hits} />
			)}
		</Button>
	);
};

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${props => props.theme.colors.blue};
	color: white;
	padding: 1rem;
	margin-top: 1rem;
	font-size: 1rem;
	border-radius: 5px;
	border: 0;
	z-index: 1;

	&:hover {
		cursor: pointer;
	}

	${props =>
		props.disabled &&
		css`
			background-color: gray;

			&:hover {
				cursor: not-allowed;
			}
		`}
`;

const StyledIconSearch = styled(IconSearch)`
	width: 18px;
	height: 18px;
	margin-right: 0.5rem;
`;

export default withRouter(ResultsButton);
