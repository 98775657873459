import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import getTypeSettings from "../utils/getTypeSettings";
import { ReactComponent as IconEyeOpen } from "../images/icon-eye-open.svg";
import { ReactComponent as IconEyeClose } from "../images/icon-eye-close.svg";
import { toggleTypeFilter } from "../reducers/filtersReducer";

const TypeToggle = ({ typeId }) => {
	const { locale } = useSelector(state => state.i18n);
	const { types } = useSelector(state => state.filters);
	const dispatch = useDispatch();
	const type = getTypeSettings(typeId, true);

	const isToggled = types.includes(typeId);

	return (
		<StyledTypeToggle
			onClick={() => dispatch(toggleTypeFilter(typeId))}
			isToggled={isToggled}
			role="switch"
			aria-checked={isToggled}
		>
			<Icon src={`/images/${type.icon}`} alt="" />
			<Name>{type.name[locale]}</Name>
			{isToggled ? <StyledIconEyeOpen /> : <StyledIconEyeClose />}
		</StyledTypeToggle>
	);
};

const StyledTypeToggle = styled.button`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: white;
	color: ${props => props.theme.colors.blue};
	border: 1px solid ${props => props.theme.colors.lightBlue};
	margin: 0.25rem 0;
	padding: 0.5rem 1rem;
	font-size: 16px;
	filter: grayscale(1);
	transition: filter, 0.25s;
	width: 100%;

	&:hover {
		cursor: pointer;
	}

	${props =>
		props.isToggled &&
		css`
			background-color: ${props => props.theme.colors.lightBlue};
			color: white;
			filter: grayscale(0);
		`}
`;

const Icon = styled.img`
	width: 32px;
	height: 32px;
	margin-right: 0;
`;

const Name = styled.span`
	margin-left: 1rem;
	margin-right: auto;
`;

const StyledIconEyeOpen = styled(IconEyeOpen)`
	width: 24px;
`;

const StyledIconEyeClose = styled(IconEyeClose)`
	width: 24px;
`;

export default TypeToggle;
