import React from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import styled, { css } from "styled-components";

const ServicesList = ({ location }) => {
	const { data: allServices } = useSelector(state => state.services);
	const { locale } = useSelector(state => state.i18n);

	return (
		<>
			{location.services !== null && (
				<>
					<h3>
						<Translate value="locationDetails.services" />:
					</h3>
					<List>
						{allServices?.services?.map(service => {
							const available = location.services.includes(service.id);

							if (available) {
								return (
									<Service
										key={service.id}
										available={available}
										aria-hidden={!available}
									>
										{service.name[locale]}
									</Service>
								);

							}
						})}
					</List>
				</>
			)}

			{location.waste !== null && (
				<>
					<h3>
						<Translate value="locationDetails.wasteServices" />:
					</h3>
					<List>
						{allServices?.waste?.map(service => {
							const available = location.waste.includes(service.id);

							if (available) {
								return (
									<Service
										key={service.id}
										available={available}
										aria-hidden={!available}
									>
										{service.name[locale]}
									</Service>
								);

							}
						})}
					</List>
				</>
			)}
		</>
	);
};

const List = styled.ul`
	padding-left: 1rem;

	@media (min-width: 600px) {
		column-count: 2;
	}
`;

const Service = styled.li`
	display: flex;
	align-items: center;
	margin-bottom: 0.33rem;
	margin-left: -1.25rem;
	padding-right: 1.25rem;

	&:before {
		content: "";
		background-repeat: no-repeat;
		width: 1rem;
		height: 1rem;
		display: inline-block;
		background-size: contain;
		background-position: center center;
		margin-right: 0.5rem;
	}

	${props =>
		props.available &&
		css`
			color: ${props => props.theme.colors.green};

			&:before {
				background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%2301c283' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>");
			}
		`}

	${props =>
		!props.available &&
		css`
			color: ${props => props.theme.colors.red};

			&:before {
				background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='times' class='svg-inline--fa fa-times fa-w-11' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'><path fill='%23d24654' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path></svg>");
			}
		`}
`;

export default ServicesList;
