import React from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import styled from "styled-components";
import getTypeSettings from "../utils/getTypeSettings";

const Info = () => {
	const { locale } = useSelector(state => state.i18n);
	const { data: info } = useSelector(state => state.info);

	const types = [
		getTypeSettings(0),
		getTypeSettings(1),
		getTypeSettings(2, true),
		getTypeSettings(2, false)
	];
	return (
		<Container>
			{info?.content && (
				<Paragraph
					dangerouslySetInnerHTML={{ __html: info?.content[locale] }}
				></Paragraph>
			)}

			{info?.definitions && (
				<>
					<h2>
						<Translate value="info.legends" />
					</h2>
					<NameRow>
						<Icon src={`images/${types[1].icon}`} alt="" />
						<Name>{types[1].name[locale]}</Name>
					</NameRow>
					<Paragraph
						dangerouslySetInnerHTML={{
							__html: info.definitions.roope_service[locale],
						}}
					></Paragraph>
					<NameRow>
						<Icon src={`images/${types[0].icon}`} alt="" />
						<Name>{types[0].name[locale]}</Name>
					</NameRow>
					<Paragraph
						dangerouslySetInnerHTML={{
							__html: info.definitions.roope_harbor[locale],
						}}
					></Paragraph>
					<NameRow>
						<Icon src={`images/${types[2].icon}`} alt="" />
						<Name>
							{types[2].name[locale]} (
							<Translate value="locationDetails.isFunctional" />)
						</Name>
					</NameRow>
					<Paragraph
						dangerouslySetInnerHTML={{
							__html: info.definitions.septic_in_order[locale],
						}}
					></Paragraph>
					<NameRow>
						<Icon src={`images/${types[3].icon}`} alt="" />
						<Name>
							{types[3].name[locale]} (
							<Translate value="locationDetails.isNotFunctional" />)
						</Name>
					</NameRow>
					<Paragraph
						dangerouslySetInnerHTML={{
							__html: info.definitions.septic_out_of_order[locale],
						}}
					></Paragraph>
				</>
			)}

			{info?.contacts && (
				<>
					<h2>
						<Translate value="info.contacts" />
					</h2>
					<Paragraph
						dangerouslySetInnerHTML={{ __html: info?.contacts[locale] }}
					></Paragraph>
				</>
			)}
		</Container>
	);
};

const Container = styled.div`
	max-width: 600px;
	margin: 0 auto;
	padding: 120px 20px 20px 20px;
`;

const NameRow = styled.div`
	display: flex;
	align-items: center;
`;

const Name = styled.h3`
	font-size: 1.1rem;
	font-family: ${props => props.theme.baseFont};
	margin: 0;
`;

const Icon = styled.img`
	width: 32px;
	height: 32px;
	margin-right: 0.5rem;
`;

const Paragraph = styled.p`
	margin-top: 0.5rem;
	margin-bottom: 2rem;
`;

export default Info;
