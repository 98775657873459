import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { I18n, setLocale } from "react-redux-i18n";
import { ThemeProvider } from "styled-components";
import { fetchLocations } from "./reducers/locationsReducer";
import { fetchServices } from "./reducers/servicesReducer";
import { fetchInfo } from "./reducers/infoReducer";
import { history } from "./store";

import Map from "./components/Map";
import List from "./components/List";
import Info from "./components/Info";
import Settings from "./components/Settings";
import Modal from "./components/Modal";
import Header from "./components/Header";
import ViewBar from "./components/ViewBar";

const App = () => {
	const { locale } = useSelector((state) => state.i18n);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchLocations());
		dispatch(fetchServices());
		dispatch(fetchInfo());
	});

	useEffect(() => {
		let language = localStorage.getItem("language");

		if (!language) {
			if (window.location.hostname.includes("sallekarta")) {
				language = "sv";
			}
		}
		if (language) {
			dispatch(setLocale(language));
		}
	}, [dispatch]);

	const theme = {
		colors: {
			blue: "#004e84",
			lightBlue: "#0071bc",
			green: "#01c283",
			red: "#d24654",
		},
		baseFont: "'Open Sans', sans-serif",
		headingFont: "'Varela Round', sans-serif",
	};

	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<html lang={locale} />
				<title>{I18n.t("head.title")}</title>
				<meta
					name="description"
					content={I18n.t("head.description")}
				></meta>
			</Helmet>
			<ConnectedRouter history={history}>
				<Header />
				<main>
					<Switch>
						<Route exact path="/">
							<Redirect to="/map" />
						</Route>
						<Route path="/map" component={Map} />
						<Route path="/list" component={List} />
						<Route path="/info" component={Info} />
						<Route path="/settings" component={Settings} />
					</Switch>

					<Route path={["/map", "/list"]} component={ViewBar} />
					<Route
						exact
						path={["/map/:id", "/list/:id"]}
						component={Modal}
					/>
				</main>
			</ConnectedRouter>
		</ThemeProvider>
	);
};

export default App;
