const initialState = {
	isFiltered: false,
	filteredLocations: null,
	types: [0, 1, 2],
	services: [],
	waste: []
};

export const filterResults = () => {
	return (dispatch, getState) => {
		dispatch({
			type: "FILTER_RESULTS",
			locations: getState().locations.data
		});
	};
};

export const toggleTypeFilter = id => {
	return (dispatch, getState) => {
		dispatch({
			type: "TOGGLE_TYPE_FILTER",
			data: id
		});
		dispatch({
			type: "FILTER_RESULTS",
			locations: getState().locations.data
		});
	};
};

export const toggleServiceFilter = (id, serviceType) => {
	return (dispatch, getState) => {
		dispatch({
			type: "TOGGLE_SERVICE_FILTER",
			id,
			serviceType
		});
		dispatch({
			type: "FILTER_RESULTS",
			locations: getState().locations.data
		});
	};
};

export const clearFilters = () => {
	return {
		type: "CLEAR_FILTERS"
	};
};

const filtersReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FILTER_RESULTS": {
			const filteredLocations = action.locations
				.filter(location => state.types.includes(location.type))
				.filter(location => {
					return state.services.every(service =>
						location.services?.includes(service)
					);
				})
				.filter(location => {
					return state.waste.every(wasteService =>
						location.waste?.includes(wasteService)
					);
				});
			const hits = filteredLocations.length;
			const isFiltered =
				filteredLocations.length !== action.locations.length;

			return { ...state, filteredLocations, hits, isFiltered };
		}
		case "TOGGLE_TYPE_FILTER": {
			const types = state.types.includes(action.data)
				? state.types.filter(type => type !== action.data)
				: [...state.types, action.data];

			return {
				...state,
				types
			};
		}
		case "TOGGLE_SERVICE_FILTER": {
			const services = state[action.serviceType].includes(action.id)
				? state[action.serviceType].filter(type => type !== action.id)
				: [...state[action.serviceType], action.id];

			return Object.assign({}, state, {
				[action.serviceType]: services
			});
		}
		case "CLEAR_FILTERS": {
			return initialState;
		}
		default:
			return state;
	}
};

export default filtersReducer;
