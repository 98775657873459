import React from "react";
import { Map as LeafletMap, TileLayer, Marker, Tooltip } from "react-leaflet";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import { usePosition } from "../hooks/usePosition";
import L from "leaflet";
import getTypeSettings from "../utils/getTypeSettings";
import MarkerClusterGroup from "./MarkerClusterGroup";
require("./MarkerClusterGroup.css");

const Map = ({ history }) => {
	const { locale } = useSelector(state => state.i18n);
	const { data: allLocations } = useSelector(state => state.locations);
	const { filteredLocations } = useSelector(state => state.filters);
	const locations = filteredLocations ? filteredLocations : allLocations;

	const { latitude: userLatitude, longitude: userLongitude } = usePosition();

	const getBounds = () => {
		const bounds = [];

		if (userLongitude && userLongitude) {
			bounds.push([userLatitude, userLongitude]);
		}

		if (locations && locations.length > 0) {
			bounds.push(
				locations?.map(location => {
					return [
						location.coordinates.lat,
						location.coordinates.long
					];
				})
			);
		} else {
			bounds.push([65.8, 19.6], [59, 31.5]);
		}

		return bounds;
	};

	const handleMarkerClick = id => {
		history.push(`/map/${id}`);
	};

	return (
		<LeafletMap
			bounds={getBounds()}
			maxZoom={14}
			zoomControl={false}
			style={{ height: "100vh" }}
		>
			<TileLayer
				attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<MarkerClusterGroup>
				{locations?.map(location => {
					const type = getTypeSettings(
						location.type,
						location.isFunctional
					);

					const icon = L.icon({
						iconUrl: `/images/${type.icon}`,
						iconSize: [32, 32]
					});
					return (
						<Marker
							onClick={event =>
								handleMarkerClick(event.target.options.id)
							}
							id={location.id}
							key={location.id}
							position={[
								location.coordinates.lat,
								location.coordinates.long
							]}
							icon={icon}
						>
							<Tooltip direction="top" offset={[0, -15]}>
								{location.name[locale]}
							</Tooltip>
						</Marker>
					);
				})}
			</MarkerClusterGroup>

			{userLatitude && userLongitude && (
				<Marker position={[userLatitude, userLongitude]}>
					<Tooltip direction="top" offset={[0, -15]}>
						<Translate value="mapView.userPosition" />
					</Tooltip>
				</Marker>
			)}
		</LeafletMap>
	);
};

export default Map;
