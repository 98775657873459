function truncate(n) {
	return n > 0 ? Math.floor(n) : Math.ceil(n);
}

let decimalDegreeToDegressMinutes = function(dd, longOrLat) {
	let hemisphere = /^[WE]|(?:lon)/i.test(longOrLat)
		? dd < 0
			? "W"
			: "E"
		: dd < 0
		? "S"
		: "N";

	const absDD = Math.abs(dd);
	const degrees = truncate(absDD);
	const minutes =
		Math.round(((absDD - degrees) * 60 + Number.EPSILON) * 10) / 10;
	// const seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(
	// 	2
	// );

	let dmsArray = [degrees, minutes, hemisphere];
	return `${dmsArray[0]}°${dmsArray[1].toString().replace(/[.]/, ",")}' ${
		dmsArray[2]
	}`;
};

export default decimalDegreeToDegressMinutes;
