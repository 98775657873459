import React from "react";
import { Translate } from "react-redux-i18n";
import styled from "styled-components";
import { useSelector } from "react-redux";
import decimalDegreeToDegressMinutes from "../utils/conversion";

const LocationDetails = ({ location }) => {
	const { locale } = useSelector(state => state.i18n);

	return (
		<>
			<Name>{location.name[locale]}</Name>

			{/* Septic info */}
			{location.type === 2 && (
				<Group>
					{location.isFunctional && (
						<StatusRow isFunctional={true}>
							<strong>
								<Translate value="locationDetails.isFunctional" />
							</strong>
						</StatusRow>
					)}
					{location.isFunctional === false &&
						location.isServiced === true && (
							<StatusRow isFunctional={false}>
								<strong>
									<Translate value="locationDetails.isServiced" />
								</strong>
							</StatusRow>
						)}
					{location.isFunctional === false &&
						location.isServiced === false && (
							<StatusRow isFunctional={false}>
								<strong>
									<Translate value="locationDetails.isNotFunctional" />
								</strong>
							</StatusRow>
						)}
					{location.isFloating && (
						<DetailRow>
							<Translate value="locationDetails.floatingSeptic" />
						</DetailRow>
					)}
				</Group>
			)}
			<Group>
				{location.septicId && (
					<DetailRow>
						<strong>
							<Translate value="locationDetails.septicId" />:
						</strong>{" "}
						{location.septicId}
					</DetailRow>
				)}
				{location.portId && (
					<DetailRow>
						<strong>
							<Translate value="locationDetails.portId" />:
						</strong>{" "}
						{location.portId}
					</DetailRow>
				)}
				{location.mapNumber && (
					<DetailRow>
						<strong>
							<Translate value="locationDetails.seaMap" />:
						</strong>{" "}
						{location.mapNumber}
					</DetailRow>
				)}
				{location.coordinates && (
					<DetailRow>
						<strong>
							<Translate value="locationDetails.coordinates" />:
						</strong>{" "}
						{decimalDegreeToDegressMinutes(
							location.coordinates.lat,
							"lat"
						)}{" "}
						{decimalDegreeToDegressMinutes(
							location.coordinates.long,
							"lon"
						)}
					</DetailRow>
				)}
			</Group>
		</>
	);
};

const Name = styled.h2`
	margin-top: 0;
`;

const DetailRow = styled.p`
	margin: 0;
`;

const StatusRow = styled.p`
	color: ${props => (props.isFunctional ? "#01c283" : "#d24654")};
	text-transform: uppercase;
	margin-bottom: 0;
`;

const Group = styled.div`
	margin-bottom: 1rem;
`;

export default LocationDetails;
