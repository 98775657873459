import servicesService from "../services/services";

export const fetchServices = () => {
	return async dispatch => {
		dispatch({
			type: "FETCH_SERVICES_REQUEST"
		});

		try {
			const services = await servicesService.getServices();
			dispatch({
				type: "FETCH_SERVICES_SUCCESS",
				data: services
			});
		} catch (error) {
			dispatch({
				type: "FETCH_SERVICES_FAILURE",
				data: error
			});
		}
	};
};

const servicesReducer = (state = [], action) => {
	switch (action.type) {
		case "FETCH_SERVICES_REQUEST":
			return { ...state, isFetching: true };
		case "FETCH_SERVICES_SUCCESS":
			return { ...state, isFetching: false, data: action.data };
		case "FETCH_SERVICES_FAILURE":
			return {
				...state,
				isFetching: false,
				hasFetchError: true,
				errorMessage: action.data.message
			};
		default:
			return state;
	}
};

export default servicesReducer;
