import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import {
	i18nReducer,
	setLocale,
	loadTranslations,
	syncTranslationWithStore,
} from "react-redux-i18n";
import translations from "./translations/translations";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { googleAnalytics } from "./middleware/reactGA";
import ReactGA from "react-ga";

import locationsReducer from "./reducers/locationsReducer";
import servicesReducer from "./reducers/servicesReducer";
import filtersReducer from "./reducers/filtersReducer";
import infoReducer from "./reducers/infoReducer";
import reportReducer from "./reducers/reportReducer";

export const history = createBrowserHistory();

ReactGA.initialize("UA-5395062-5");

const reducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		locations: locationsReducer,
		services: servicesReducer,
		filters: filtersReducer,
		info: infoReducer,
		report: reportReducer,
		i18n: i18nReducer,
	});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducer(history),
	composeEnhancers(
		applyMiddleware(routerMiddleware(history), thunk, googleAnalytics)
	)
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale("fi"));

export default store;
