import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import styled from "styled-components";
import Dropdown from "./Modal-Dropdown";
import { postReport, clearReportStatus } from "../reducers/reportReducer";
import translations from "../translations/translations";

const Report = ({ history, locationItem, changeView }) => {
	const { locale } = useSelector(state => state.i18n);
	const { isPosting, isSuccess, hasPostError, errorMessage } = useSelector(
		state => state.report
	);
	const dispatch = useDispatch();
	const [issueType, setIssueType] = useState(0);
	const [otherIssue, setOtherIssue] = useState("");

	const handleSubmit = event => {
		event.preventDefault();
		dispatch(postReport(locationItem, issueType, otherIssue));
	};

	const handleSelectChange = issueType => {
		setIssueType(issueType);
		setOtherIssue("");
	};

	const handleInputChange = event => {
		setOtherIssue(event.target.value);
	};

	useEffect(() => {
		return () => {
			dispatch(clearReportStatus());
		};
	}, [dispatch]);

	return (
		<Content>
			<Heading>
				<Translate value="locationDetails.reportIssue" />
			</Heading>
			<Name>{locationItem.name[locale]}</Name>
			<Close onClick={() => history.goBack()} aria-label={translations[locale].close}>
				<span aria-hidden="true">✕</span>
			</Close>

			{!isPosting && !isSuccess && (
				<form onSubmit={event => handleSubmit(event)}>
					<Dropdown onChange={handleSelectChange} />

					{issueType === 2 && (
						<>
							<label htmlFor="otherIssue">
								<Translate value="locationDetails.issueDescription" />
							</label>
							<Input
								type="text"
								id="otherIssue"
								name="otherIssue"
								value={otherIssue}
								onChange={event => handleInputChange(event)}
							/>
						</>
					)}
					<SendButton>
						<Translate value="locationDetails.send" />
					</SendButton>
					<LinkButton onClick={changeView}>
						<Translate value="locationDetails.cancel" />
					</LinkButton>

					{hasPostError && (
						<>
							<Error>
								<Translate value="locationDetails.postError" />
							</Error>{" "}
							<span>({errorMessage})</span>
						</>
					)}
				</form>
			)}

			{isPosting && (
				<p>
					<Translate value="sending" />
					...
				</p>
			)}

			{isSuccess && (
				<Success>
					<Translate value="locationDetails.postSuccess" />
				</Success>
			)}
		</Content>
	);
};

const Content = styled.div`
	position: relative;
	padding: 20px 20px 40px;
`;

const Heading = styled.h2`
	margin-top: 0;
	font-weight: normal;
	font-family: ${props => props.theme.baseFont};
`;

const Name = styled.h3`
	margin-top: 0;
`;

const LinkButton = styled.button`
	background: none;
	border: none;
	font-size: 16px;
	margin: 0 1rem;
	padding: 0;
	color: ${props => props.theme.colors.lightBlue};

	&:hover {
		cursor: pointer;
	}
`;

const SendButton = styled.button`
	background-color: ${props => props.theme.colors.green};
	color: white;
	font-size: 16px;
	padding: 0.5rem 1rem;
	border: 0;
	border-radius: 5px;
	font-family: ${props => props.theme.baseFont};

	&:hover {
		cursor: pointer;
	}
`;

const Input = styled.textarea`
	width: calc(100% - 2rem);
	font-size: 16px;
	margin: 0.25rem 0 1rem;
	padding: 0.75rem 1rem;
`;

const Error = styled.p`
	color: ${props => props.theme.colors.red};
	margin-bottom: 0;
`;

const Success = styled.p`
	color: ${props => props.theme.colors.green};
	margin-bottom: 0;
	font-size: 20px;

	&:before {
		content: "";
		background-repeat: no-repeat;
		width: 1rem;
		height: 1rem;
		display: inline-block;
		background-size: contain;
		background-position: center center;
		margin-right: 0.5rem;
		background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%2301c283' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>");
	}
`;

const Close = styled.button`
	position: absolute;
	top: 20px;
	right: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	min-width: 36px;
	min-height: 36px;
	max-width: 36px;
	max-height: 36px;
	border: 0;
	font-size: 1.5rem;
	color: ${props => props.theme.colors.blue};
	background: transparent;
	border: 2px solid ${props => props.theme.colors.blue};
	transition: border, 0.25s;
	padding: 0;

	&:hover {
		cursor: pointer;
		border: 2px solid ${props => props.theme.colors.lightBlue};
	}
`;

export default withRouter(Report);
