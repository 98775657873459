import React from "react";
import { useDispatch } from "react-redux";
import { Translate } from "react-redux-i18n";
import { useSpring, animated, config } from "react-spring";
import { clearFilters } from "../reducers/filtersReducer";
import styled from "styled-components";
import { ReactComponent as IconTimes } from "../images/icon-times.svg";

const ResetButton = () => {
	const dispatch = useDispatch();

	const buttonSpring = useSpring({
		opacity: 1,
		transform: "translate3d(0, 0, 0)",
		from: { opacity: 0.5, transform: "translate3d(0, -25px, 0)" },
		config: config.stiff
	});

	return (
		<Button type="reset" style={buttonSpring} onClick={() => dispatch(clearFilters())}>
			<StyledIconTimes />
			<Translate value="settings.reset" />
		</Button>
	);
};

const Button = styled(animated.button)`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${props => props.theme.colors.red};
	background: #f0f0f0;
	padding: 1rem;
	margin-top: 1rem;
	font-size: 1rem;
	border-radius: 5px;
	border: 0;
	z-index: 0;

	&:hover {
		cursor: pointer;
	}
`;

const StyledIconTimes = styled(IconTimes)`
	width: 18px;
	height: 18px;
	margin-right: 0.5rem;
`;

export default ResetButton;
