import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Heading from "./Header-Heading";
import styled, { css } from "styled-components";
import { ReactComponent as Logo } from "../images/pss-logo.svg";
import { ReactComponent as IconSettings } from "../images/icon-settings.svg";
import { ReactComponent as IconInfo } from "../images/icon-info.svg";
import translations from "../translations/translations";

const Header = ({ location, history }) => {
	const { isFiltered } = useSelector(state => state.filters);
	const { locale } = useSelector(state => state.i18n);

	return (
		<StyledHeader>
			<StyledLogo />
			<Heading />
			<Controls>
				{location.pathname.includes("map") ||
				location.pathname.includes("list") ? (
					<>
						<StyledLink to="/info" aria-label={translations[locale].info.heading}>
							<StyledIconInfo />
						</StyledLink>
						<StyledLink to="/settings" aria-label={translations[locale].settings.heading}>
							<FilterIndicator isFiltered={isFiltered}>
								<StyledIconSettings />
							</FilterIndicator>
						</StyledLink>
					</>
				) : (
					<>
						<Close onClick={() => history.goBack()} aria-label={translations[locale].close}>
							<span aria-hidden="true">✕</span>
						</Close>
					</>
				)}
			</Controls>
		</StyledHeader>
	);
};

const StyledHeader = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	width: calc(100% - 40px);
	height: 60px;
	top: 20px;
	left: 40px;
	z-index: 1000;
	background-color: ${props => props.theme.colors.blue};
`;

const StyledLink = styled(Link)`
	color: white;
`;

const StyledLogo = styled(Logo)`
	height: 80px;
	position: absolute;
	top: -10px;
	left: -20px;
`;

const StyledIconInfo = styled(IconInfo)`
	width: 32px;
	margin-right: 10px;
`;

const StyledIconSettings = styled(IconSettings)`
	width: 32px;
	margin-left: 10px;
`;

const FilterIndicator = styled.div`
	position: relative;

	${props =>
		props.isFiltered &&
		css`
			&:before {
				content: "";
				position: absolute;
				top: -5px;
				right: -5px;
				background-color: ${props => props.theme.colors.red};
				color: white;
				width: 20px;
				height: 20px;
				border-radius: 50%;
			}

			&:after {
				content: "";
				position: absolute;
				width: 10px;
				height: 10px;
				top: 0;
				right: 0;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='filter' class='svg-inline--fa fa-filter fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='white' d='M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z'></path></svg>");
			}
		`}
`;

const Controls = styled.nav`
	display: flex;
	margin-left: auto;
	margin-right: 20px;
`;

const Close = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	min-width: 36px;
	min-height: 36px;
	max-width: 36px;
	max-height: 36px;
	border: 0;
	font-size: 1.5rem;
	color: white;
	background: transparent;
	border: 2px solid white;
	transition: border, 0.25s;
	padding: 0;

	&:hover {
		cursor: pointer;
		border: 2px solid rgba(255, 255, 255, 0.5);
	}
`;

export default withRouter(Header);
