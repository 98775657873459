import infoService from "../services/info";

export const fetchInfo = () => {
	return async dispatch => {
		dispatch({
			type: "FETCH_INFO_REQUEST"
		});

		try {
			const info = await infoService.getInfo();
			dispatch({
				type: "FETCH_INFO_SUCCESS",
				data: info
			});
		} catch (error) {
			dispatch({
				type: "FETCH_INFO_FAILURE",
				data: error
			});
		}
	};
};

const infoReducer = (state = [], action) => {
	switch (action.type) {
		case "FETCH_INFO_REQUEST":
			return { ...state, isFetching: true };
		case "FETCH_INFO_SUCCESS":
			return {
				...state,
				isFetching: false,
				data: action.data,
				hits: action.data.length
			};
		case "FETCH_INFO_FAILURE":
			return {
				...state,
				isFetching: false,
				hasFetchError: true,
				errorMessage: action.data.message
			};
		default:
			return state;
	}
};

export default infoReducer;
