import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Translate } from "react-redux-i18n";

const Heading = ({ location }) => {
	if (location.pathname.includes("info")) {
		return (
			<StyledHeading>
				<Translate value="info.heading" />
			</StyledHeading>
		);
	} else if (location.pathname.includes("settings")) {
		return (
			<StyledHeading>
				<Translate value="settings.heading" />
			</StyledHeading>
		);
	} else {
		return (
			<StyledHeading>
				<Translate value="header.heading" />{" "}
				<Sub>
					<Translate value="header.subheading" />
				</Sub>
			</StyledHeading>
		);
	}
};

const StyledHeading = styled.h1`
	color: white;
	font-size: 1.65rem;
	margin: 0;
	font-weight: normal;
	margin-top: -0.2rem;
	padding-left: 75px;
	user-select: none;
`;

const Sub = styled.span`
	display: block;
	text-transform: none;
	font-size: 0.9rem;
	margin-top: -0.2rem;
	opacity: 0.75;
	user-select: none;
`;

export default withRouter(Heading);
