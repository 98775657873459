import React, { useState, useEffect, useRef, Fragment } from "react";
import { useSelector } from "react-redux";
import { useSpring, animated, config } from "react-spring";
import { Translate } from "react-redux-i18n";
import styled from "styled-components";
import Report from "./Modal-Report";
import ServicesList from "./Modal-ServicesList";
import LocationDetails from "./Modal-LocationDetails";
import getTypeSettings from "../utils/getTypeSettings";
import translations from "../translations/translations";

const Modal = ({ match, history }) => {
	const { locale } = useSelector(state => state.i18n);
	const { data: locations } = useSelector(state => state.locations);
	const [view, setView] = useState("location");
	const closeButton = useRef();

	useEffect(() => {
		if (closeButton.current) {
			closeButton.current.focus();
		}
	}, []);

	const wrapperSpring = useSpring({
		background: "rgba(0, 0, 0, 0.6)",
		from: { background: "rgba(0, 0, 0, 0)" }
	});

	const modalWindowSpring = useSpring({
		opacity: 1,
		transform: "translate3d(0, 0, 0)",
		from: { opacity: 0, transform: "translate3d(0, -50px, 0)" },
		config: config.stiff
	});

	const location = locations?.filter(location => {
		return location.id === parseInt(match.params.id);
	});

	const changeView = () => {
		setView(view === "location" ? "report" : "location");
	};

	return (
		<Wrapper style={wrapperSpring} onClick={() => history.goBack()}>
			<ModalWindow
				style={modalWindowSpring}
				onClick={event => event.stopPropagation()}
			>
				{location?.map(location => {
					const type = getTypeSettings(
						location.type,
						location.isFunctional
					);

					if (view === "location") {
						return (
							<Fragment key={location.id}>
								<Header bgcolor={type.color}>
									<Icon src={`/images/${type.icon}`} alt="" />
									<Name>{type.name[locale]}</Name>
									<Close
										ref={closeButton}
										onClick={() => history.goBack()}
										aria-label={translations[locale].close}
									>
										<span aria-hidden="true">✕</span>
									</Close>
								</Header>

								{location.image && (
									<Image
										srcSet={location.image.srcSet}
										src={location.image.src}
										alt=""
									/>
								)}
								<Content>
									<LocationDetails location={location} />
									{(location.services || location.waste) && (
										<ServicesList location={location} />
									)}

									{location.url[locale] && (
										<a href={location.url[locale]}>
											<Translate value="locationDetails.website" />
										</a>
									)}

									{location.type === 2 && (
										<LinkButton onClick={changeView}>
											<Translate value="locationDetails.reportIssue" />
										</LinkButton>
									)}
								</Content>
							</Fragment>
						);
					} else {
						return (
							<Report
								locationItem={location}
								changeView={changeView}
								key={location.id}
							/>
						);
					}
				})}
			</ModalWindow>
		</Wrapper>
	);
};

const Wrapper = styled(animated.div)`
	position: fixed;
	left: 0;
	top: 0;
	width: calc(100% - 40px);
	height: 100vh;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	z-index: 1000;
	padding: 20px;
`;

const ModalWindow = styled(animated.div)`
	background: white;
	max-width: 500px;
	margin: 20px auto;
	box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.5);
`;

const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${props => props.bgcolor};
	padding: 20px;
`;

const Icon = styled.img`
	width: 32px;
	height: 32px;
`;

const Name = styled.p`
	margin: 0 auto 0 1rem;
	color: white;
	font-size: 1.5rem;
`;

const Close = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	min-width: 36px;
	min-height: 36px;
	max-width: 36px;
	max-height: 36px;
	border: 0;
	font-size: 1.5rem;
	color: white;
	background: transparent;
	border: 2px solid white;
	transition: border, 0.25s;
	padding: 0;

	&:hover {
		cursor: pointer;
		border: 2px solid rgba(255, 255, 255, 0.5);
	}
`;

const Image = styled.img`
	max-width: 100%;
	height: auto;
`;

const Content = styled.div`
	padding: 20px 20px 40px;
`;

const LinkButton = styled.button`
	background: none;
	border: none;
	font-size: 16px;
	padding: 0;
	margin-top: 1rem;
	color: ${props => props.theme.colors.lightBlue};

	&:hover {
		cursor: pointer;
	}
`;

export default Modal;
