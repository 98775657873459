import { useState, useEffect } from "react";

export const usePosition = () => {
	const [position, setPosition] = useState({});

	const onChange = ({ coords }) => {
		setPosition({
			latitude: coords.latitude,
			longitude: coords.longitude
		});
	};

	const onError = error => {
		console.log(error);
	};

	useEffect(() => {
		const geo = navigator.geolocation;

		if (!geo) {
			console.log("Geolocation is not supported");
			return;
		}

		const watcher = geo.watchPosition(onChange, onError);

		return () => geo.clearWatch(watcher);
	}, []);

	return { ...position };
};
