import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { toggleServiceFilter } from "../reducers/filtersReducer";

const Checkbox = ({ name, id, type }) => {
	const { services, waste } = useSelector(state => state.filters);
	const dispatch = useDispatch();

	const filters = type === "services" ? services : waste;
	const isChecked = filters.includes(id);

	return (
		<StyledCheckbox>
			<Input
				id={`${type}-${id}`}
				name={`${type}-${id}`}
				checked={isChecked}
				aria-checked={isChecked}
				onChange={() => dispatch(toggleServiceFilter(id, type))}
				type="checkbox"
			></Input>
			<Label htmlFor={`${type}-${id}`}>{name}</Label>
		</StyledCheckbox>
	);
};

const StyledCheckbox = styled.div`
	display: flex;
	margin: 0.25rem 0;
`;

const Label = styled.label`
	margin-left: 0.25rem;

	&:after {
		content: "";
		position: absolute;
		left: 6px;
		top: 10px;
		background: white;
		width: 2px;
		height: 2px;
		transform: rotate(45deg);
	}
`;

const Input = styled.input`
	position: absolute;
	opacity: 0;

	& + ${Label} {
		position: relative;
		cursor: pointer;
		padding: 0;
	}

	& + ${Label}:before {
		content: "";
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 20px;
		height: 20px;
		background: white;
		border: 1px solid ${props => props.theme.colors.lightBlue};
		border-radius: 3px;
	}

	/* &:hover + ${Label}:after {
		box-shadow: 2px 0 0 ${props => props.theme.colors.lightBlue},
			4px 0 0 ${props => props.theme.colors.lightBlue},
			4px -2px 0 ${props => props.theme.colors.lightBlue},
			4px -4px 0 ${props => props.theme.colors.lightBlue},
			4px -6px 0 ${props => props.theme.colors.lightBlue},
			4px -8px 0 ${props => props.theme.colors.lightBlue};
	} */

	&:focus + ${Label}:before {
		outline: -webkit-focus-ring-color auto 5px;
	}

	&:checked + ${Label}:before {
		background: ${props => props.theme.colors.lightBlue};
	}

	&:disabled + ${Label} {
		color: #b8b8b8;
		cursor: auto;
	}

	&:disabled + ${Label}:before {
		box-shadow: none;
		background: #ddd;
	}

	&:checked + ${Label}:after {
		box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
			4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
	}
`;

export default Checkbox;
