import React from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import TypeToggle from "./Settings-TypeToggle.js";
import LanguageSwitcher from "./Settings-LanguageSwitcher.js";
import Checkbox from "./Settings-Checkbox.js";
import ResultsButton from "./Settings-ResultsButton.js";
import ResetButton from "./Settings-ResetButton.js";
import styled from "styled-components";

const Settings = () => {
	const { locale } = useSelector(state => state.i18n);
	const { data: allServices } = useSelector(state => state.services);
	const { isFiltered } = useSelector(state => state.filters);

	return (
		<Container>
			<LanguageSwitcher />
			<MainHeading>
				<Translate value="settings.filtering" />
			</MainHeading>
			<Heading id="filters-heading">
				<Translate value="settings.show" />:
			</Heading>
			<nav aria-labelledby="filters-heading">
				<TypeToggle typeId={1} />
				<TypeToggle typeId={0} />
				<TypeToggle typeId={2} />
			</nav>

			<Heading id="services-heading">
				<Translate value="settings.requiredServices" />:
			</Heading>

			<form aria-labelledby="services-heading">
				{allServices?.services?.map(service => {
					return (
						<Checkbox
							key={service.id}
							id={service.id}
							name={service.name[locale]}
							type="services"
						/>
					);
				})}
			</form>

			<Heading id="waste-heading">
				<Translate value="settings.requiredWasteServices" />:
			</Heading>

			<form aria-labelledby="waste-heading">
				{allServices?.waste?.map(service => {
					return (
						<Checkbox
							key={service.id}
							id={service.id}
							name={service.name[locale]}
							type="waste"
						/>
					);
				})}
			</form>

			<ResultsButton />
			{isFiltered && <ResetButton />}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 600px;
	margin: 0 auto;
	padding: 120px 20px 20px 20px;
`;

const MainHeading = styled.h2`
	margin-top: 0.25rem;
	margin-bottom: 0.15rem;
	font-size: 1.5rem;
`;

const Heading = styled.h3`
	font-family: ${props => props.theme.baseFont};
	margin-bottom: 0.5rem;
	font-size: 1.1rem;
`;

export default Settings;
