import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLocale } from "react-redux-i18n";
import styled, { css } from "styled-components";
import translations from "../translations/translations";

const LanguageSwitcher = () => {
	const { locale } = useSelector(state => state.i18n);
	const dispatch = useDispatch();

	const supportedLocales = {
		fi: "Suomi",
		sv: "Svenska",
		en: "English"
	};

	const handleChange = code => {
		localStorage.setItem("language", code);
		dispatch(setLocale(code));
	};

	return (
		<LanguageBar aria-label={translations[locale].settings.languageSelection}>
			{Object.keys(supportedLocales).map(code => (
				<StyledLanguageSwitcher key={code} isActive={code === locale}>
					<Input
						type="radio"
						id={`lang-${code}`}
						name="lang"
						value={code}
						checked={code === locale}
						aria-checked={code === locale}
						onChange={() => handleChange(code)}
					/>
					<Label htmlFor={`lang-${code}`}>{supportedLocales[code]}</Label>
				</StyledLanguageSwitcher>
			))}
		</LanguageBar>
	);
};

const LanguageBar = styled.form`
	display: flex;
	width: 100%;
	margin-bottom: 2rem;
`;

const StyledLanguageSwitcher = styled.div`
	flex-grow: 1;
	background-color: white;
	color: ${props => props.theme.colors.blue};
	border: 1px solid ${props => props.theme.colors.lightBlue};
	margin: 0.25rem 0;
	font-size: 16px;

	&:first-child {
		border-radius: 10px 0 0 10px;
		border-right: 0;
	}

	&:last-child {
		border-radius: 0 10px 10px 0;
		border-left: 0;
	}

	${props =>
		props.isActive &&
		css`
			background-color: ${props => props.theme.colors.lightBlue};
			color: white;
		`}
`;

const Label = styled.label`
	display: flex;
	justify-content: center;
	padding: 0.5rem 1rem;

	&:hover {
		cursor: pointer;
	}
`;

const Input = styled.input`
	position: absolute;
	opacity: 0;

	&:focus + ${Label} {
		outline: -webkit-focus-ring-color auto 5px;
	}
`;

export default LanguageSwitcher;
