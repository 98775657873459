import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import styled, { css } from "styled-components";
import getTypeSettings from "../utils/getTypeSettings";
import Dropdown from "./List-Dropdown";

const List = ({ history }) => {
	const { locale } = useSelector(state => state.i18n);
	const { data: allLocations } = useSelector(state => state.locations);
	const { filteredLocations } = useSelector(state => state.filters);
	const locations = filteredLocations ? filteredLocations : allLocations;

	const [selectedArea, setSelectedArea] = useState(-1);

	const handleLocationClick = id => {
		history.push(`/list/${id}`);
	};

	const handleLocationKeyDown = (event, id) => {
		if (event.key === "Enter") {
			history.push(`/list/${id}`);
		}
	};

	const sortByName = (a, b) => {
		const nameA = a.name[locale].toUpperCase();
		const nameB = b.name[locale].toUpperCase();
		if (nameA < nameB) {
			return -1;
		}

		if (nameA > nameB) {
			return 1;
		}

		return 0;
	};

	return (
		<Container>
			<Dropdown onChange={setSelectedArea} />
			<Table>
				<TableHeader>
					<tr>
						<TableHeaderCell
							style={{ width: "24px" }}
						></TableHeaderCell>
						<TableHeaderCell>
							<Translate value="listView.name" />
						</TableHeaderCell>
						<TableHeaderCell>
							<Translate value="listView.number" />
						</TableHeaderCell>
						<TableHeaderCell>
							<Translate value="listView.seaMap" />
						</TableHeaderCell>
					</tr>
				</TableHeader>
				<tbody>
					{locations
						?.sort(sortByName)
						.filter(
							location =>
								selectedArea < 0 || location.area === selectedArea
						)
						.map(location => {
							const type = getTypeSettings(
								location.type,
								location.isFunctional
							);

							return (
								<TableRow
									key={location.id}
									onClick={() => handleLocationClick(location.id)}
									onKeyDown={(event) =>
										handleLocationKeyDown(event, location.id)
									}
									tabIndex={0}
								>
									<TableCell>
										<Icon src={`/images/${type.icon}`} alt="" />
									</TableCell>
								<TableCell linkColor>{location.name[locale]}</TableCell>
									<TableCell>
										{location.portId || location.septicId}
									</TableCell>
									<TableCell>{location.mapNumber}</TableCell>
								</TableRow>
							);
						})}
				</tbody>
			</Table>
		</Container>
	);
};

const Container = styled.div`
	max-width: 600px;
	margin: 0 auto;
	padding: 120px 20px 20px 20px;
`;

const Table = styled.table`
	width: 100%;
	text-align: left;
	border-collapse: collapse;
	margin-bottom: 100px;
`;

const TableHeader = styled.thead`
	margin-bottom: 1rem;
	border-bottom: ${props => `1px solid ${props.theme.colors.blue}`};
`;

const TableCell = styled.td`
	padding: 0.75rem 0.5rem;

	${props =>
		props.linkColor &&
		css`
			color: ${props => props.theme.colors.blue};
		`}
`;

const TableRow = styled.tr`
	&:hover {
		cursor: pointer;
		background: rgba(0, 78, 132, 0.05);
	}
`;

const TableHeaderCell = styled.th`
	padding: 0.75rem 0.5rem;
`;

const Icon = styled.img`
	width: 24px;
	height: 24px;
`;

export default List;
