import React from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import translations from "../translations/translations";
import styled from "styled-components";

const Dropdown = ({ onChange }) => {
	const { locale } = useSelector(state => state.i18n);
	const issueTypes = translations[locale].issueTypes.map(
		issueType => issueType
	);

	return (
		<>
			<label htmlFor="issueType-select">
				<Translate value="locationDetails.issueType" />
			</label>
			<Select
				name="issueTypes"
				id="issueType-select"
				onChange={event => onChange(parseInt(event.target.value))}
			>
				{issueTypes.map((issueType, index) => {
					return (
						<option value={index} key={index}>
							{issueType}
						</option>
					);
				})}
			</Select>
		</>
	);
};

const Select = styled.select`
	display: block;
	color: white;
	font-size: 16px;
	margin: 0.25rem 0 1rem;
	padding: 0.75rem 1rem;
	width: 100%;
	max-width: 100%;
	border-radius: 5px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	border: 0;
	background-color: ${props => props.theme.colors.blue};
	background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='white' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'></path></svg>");
	background-repeat: no-repeat;
	background-position: right 1rem top 50%;
	background-size: 0.875rem auto;

	&::-ms-expand {
		display: none;
	}
	&:hover {
		cursor: pointer;
	}
`;

export default Dropdown;
