import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import styled, { css } from "styled-components";
import { ReactComponent as IconFilter } from "../images/icon-filter.svg";
import translations from "../translations/translations";

const ViewBar = () => {
	const { locale } = useSelector(state => state.i18n);
	const { hits: allHits, isFetching } = useSelector(state => state.locations);
	const { hits: filteredHits } = useSelector(state => state.filters);
	const hits = filteredHits !== undefined ? filteredHits : allHits;

	return (
		<Container>
			<nav aria-label={translations[locale].viewBar.view}>
				<StyledNavLink activeClassName="active" to="/map">
					<Translate value="viewBar.map" />
				</StyledNavLink>
				<StyledNavLink activeClassName="active" to="/list">
					<Translate value="viewBar.list" />
				</StyledNavLink>
			</nav>

			{isFetching === true ? (
				<Loading>
					<Translate value="loading" />
					...
				</Loading>
			) : (
				<StyledLink to="/settings">
					<ResultsCount>
						<StyledIconFilter />
						<ConditionalTranslate
							hasHits={hits > 0}
							value="viewBar.showing"
						/>
						<Translate value="viewBar.results" count={hits} />
					</ResultsCount>
				</StyledLink>
			)}
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	display: flex;
	align-items: flex-start;
	padding: 0 15px;
	bottom: 0;
	left: 0;
	height: 60px;
	width: calc(100% - 30px);
	background-color: ${props => props.theme.colors.blue};
	z-index: 1000;
	font-size: 15px;

	@media (min-width: 600px) {
		width: calc(100% - 40px);
		padding: 0 20px;
		font-size: 16px;
	}
`;

const StyledLink = styled(Link)`
	margin-right: 0;
	margin-left: auto;
`;

const Loading = styled.p`
	color: white;
	margin: 0;
	margin-right: 0;
	margin-left: auto;
	display: flex;
	align-items: center;
	height: 60px;
`;

const StyledNavLink = styled(NavLink)`
	border-left: 1px solid white;
	border-right: 1px solid white;
	border-bottom: 1px solid white;
	border-radius: 0 0 10px 10px;
	color: white;
	padding: 1rem;
	display: inline-block;

	&.active {
		background-color: white;
		color: ${props => props.theme.colors.blue};
	}

	@media (min-width: 600px) {
		padding: 1rem 2rem;
	}
`;

const StyledIconFilter = styled(IconFilter)`
	width: 16px;
	height: 16px;
	margin-right: 0.5rem;
`;

const ResultsCount = styled.div`
	color: white;
	display: flex;
	align-items: center;
	height: 60px;
`;

const ConditionalTranslate = styled(Translate)`
	display: none;

	${props =>
		props.hasHits &&
		css`
			@media (min-width: 600px) {
				display: inline-block;
				margin-right: 0.33em;
			}
		`}
`;

export default ViewBar;
