import axios from "axios";
import baseUrl from "../utils/config";

const getInfo = async () => {
	let response;
	if (!navigator.onLine) {
		response = JSON.parse(localStorage.getItem("info"));
	} else {
		response = await axios.get(`${baseUrl}/info`);
		localStorage.setItem("info", JSON.stringify(response));
	}
	return response.data;
};

export default { getInfo };
