import axios from "axios";
import baseUrl from "../utils/config";

const getServices = async () => {
	let response;
	if (!navigator.onLine) {
		response = JSON.parse(localStorage.getItem("services"));
	} else {
		response = await axios.get(`${baseUrl}/services`);
		localStorage.setItem("services", JSON.stringify(response));
	}
	return response.data;
};

export default { getServices };
